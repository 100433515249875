@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');



.category {
    margin-top: 110px;
    
}

.category h2{
    font-size: 50px;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
}