.testimonials {
    margin-top: 160px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.testimonials h2 {
    font-size: 40px;
    text-transform: uppercase;
}

.testimonials p {
    font-size: 18px;
    margin: 0 40px;
}

.card {
    margin-top: auto 0;
    height: 450px;
    border-radius: 5px;
}

.card img {
    margin-top: 40px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.card h3 {
    margin: 0;
    margin-top: 10px;
}

.card span {
    margin-top: 10px;
}

.card p {
    width: 70%;

    margin: 50px auto 0 auto;
}