@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


.navBar {
    background-color: #FFFBF8;
    font-size: 24px;
    border-bottom: 0.5px solid #d4d4d4;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.logo {
    width: 100px;
}

.dropdown .dropdownToggle {
    color: #000;
    text-decoration: none;
    margin-right: 40px;
    padding: 8px;
}

.dropdown .dropdownToggle:hover,
.dropdown .dropdownToggle:focus,
.dropdown .dropdownToggle:active {
    color: #ffcd07;
}


.dropdown .dropdownMenu[data-bs-popper] {
    top: 75px;
}



.dropdown .dropdownMenu {
    box-shadow: 1px 1px 7px 0px rgba(34, 60, 80, 0.17);
    border: 1px solid #b6b6b6;
    border-radius: 0 0 5px 5px;
    padding: 10px;
    animation-name: exemple;
    animation-duration: 0.3s;
}

@keyframes exemple {
    0% {
        top: 75px;
        height: 0
    }

    100% {
        top: 75px;
        height: 230px;
    }
}


.dropdownMenu .dropdownItem {
    font-size: 18px;
    transition: all 0.3s;
    animation-name: items;
    animation-duration: 2s;
}

@keyframes items {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.dropdownMenu .dropdownItem:hover {
    color: #ffcd07;
    background-color: transparent;
}


.navBar .navLink {
    color: #000;
    margin: auto 40px auto 0;
    padding: 8px;
}


.navBar .navLink:hover {
    color: #ffcd07;
}

.navDropdownItem {
    border-radius: 5px;
    font-size: 20px;
}


.languageBtn {
    max-width: 32px;
    max-height: 32px;
    border-radius: 50%;
    margin: auto 0;
    cursor: pointer;
    position: relative;
}

.languageBtn img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #b6b6b6;
}





.languageDropdown {
    width: 40px;
    height: 90px;
    padding: 4px;
    display: flex;
    border: 1px solid #b6b6b6;
    background-color: #fff;
    box-shadow: 1px 1px 7px 0px rgba(34, 60, 80, 0.17);
    flex-direction: column;
    justify-content: space-around;
    border-radius: 0 0 5px 5px;
    position: absolute;
    top: 72px;
    left: -4px;
    animation-name: language;
    animation-duration: 0.3s;
}

@keyframes language {
    0% {
        top: 72px;
        height: 0
    }

    100% {
        top: 72px;
        height: 90px;
    }

}