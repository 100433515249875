@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Itim&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.card {
    position: relative;
    margin-top: 24px;
    height: 300px;
    cursor: pointer;
    z-index: 0;
    
}

.card img {
    height: 100%;
    border-radius: 5px;
}

.hover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: end;
    background-color: #cccccc57;
    opacity: 0;
    transition: all 0.5s;
    
}

.hover:hover {
    opacity: 1;
}

.contentContainer {
    width: 100%;
    height: 20%;
    text-align: start;
    /* display: flex;
    align-items: center; */
    background-color: #040f00a7;
    border-radius: 0 0 5px 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.title {
    margin: 5px auto 5px 15px;
    font-size: 18px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.description {
    margin: 5px auto 5px 15px;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}