.container {
    margin-bottom: 70px;
    text-align: center;
}

.container button {
    margin-top: 40px;
    padding: 12px 50px;
    border-radius: 5px;
    border: none;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
}

.container button:hover {
    background-color: #ffcd07;
}