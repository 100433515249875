@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


.lastProjects {
    margin-top: 140px;
    margin-bottom: 160px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.lastProjects h2 {
    font-size: 50px;
    text-transform: uppercase;
    font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lastProjects p {
    font-size: 18px;
    margin: 0 40px;
}

.card {
    margin-top: 40px;
}


.card:hover {
    cursor: pointer;
}

.card img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.card p {
    margin: 0;
    margin-top: 10px;
    text-align: start;
    font-size: 16px;
}

