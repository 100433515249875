@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Itim&display=swap');



.background {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
}

.background img {
    width: 100%;
}

.overlay {
    position: absolute;
    width: 460px;
    /* background-color: #e9e9e9db; */
    padding: 50px;
    top: 75%;
    left:  84%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.overlay h1 {
    font-size: 80px;
}

.overlay p {
    margin: 0;
    font-size: 18px;
    color: #eaeaea;
    font-family: "Itim", cursive;
  font-weight: 400;
  font-style: italic;
}


.overlay button {
    width: 150px;
    height: 47px;
    font-size: 20px;
    margin-top: 40px;
    background-color: #ffcd07;
    color: #fff;
    border: none;
    font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.overlay button:hover {
    background-color: #ffffff;
    color: #000;
}



@media screen and (max-width: 1250px) {
    .overlay h1 {
        font-size: 50px;
    }

    .overlay p {
        font-size: 16px;
    }

    .overlay button {
        width: 120px;
        height: 37px;
        font-size: 16px;
        border-radius: 5px;
    }

}

@media screen and (max-width: 900px) {
    .overlay h1 {
        font-size: 40px;
    }

    .overlay p {
        font-size: 14px;
    }

    .overlay button {
        width: 100px;
        height: 27px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 5px;
    }

}


@media screen and (max-width: 690px) {
    .overlay h1 {
        font-size: 30px;
    }

    .overlay p {
        font-size: 12px;
    }

    .overlay button {
        width: 80px;
        height: 22px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

}


@media screen and (max-width: 500px) {
    .overlay h1 {
        font-size: 20px;
    }

    .overlay p {
        font-size: 10px;
    }

    .overlay button {
        width: 60px;
        height: 18px;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 3px;
    }
}

@media screen and (max-width: 320px) {
    .overlay h1 {
        font-size: 18px;
    }

    .overlay p {
        font-size: 8px;
    }

    .overlay button {
        width: 60px;
        height: 18px;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 3px;
    }
}