@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Itim&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.footer {
    color: #fff;
    padding: 30px !important;
    padding-bottom: 0 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.row {
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;
}

.row a:hover {
    color: #ffcd07;
}

.projectsContainer p{
    margin: 0;
    font-size: 12px;
}
.footer h3 {
    font-size: 20px;
    font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.h3style:hover{
    color: #ffcd07;
    cursor: pointer;
}

.logoContainer img {
    width: 100px;
}

.menuContainer {
    font-size: 20px;
}

.contactContainer span {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    /* color: #ffcd07; */
}
.contactContainer p {
    margin: 0;
    margin-bottom: 2px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.copyrightContainer {
    padding: 10px;
    color: #eaeaea;
    display: flex;
    justify-content: center;
}

.copyrightContainer p {
    margin: 0;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.copyrightContainer a {
    margin: 0 5px;
    color: #7ECDB6;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.copyrightContainer a:hover {
    color: #152CB6;
}

.viber{
    color: #7152D7;
    font-size: 20px;
}

.whatsapp{
    font-size: 20px;
    color: #40E25E;
}
