@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');






.content {
    padding: 40px 70px 40px 70px;
    text-align: center;
}

.content h1 {
    font-size: 50px;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.content p {
    margin: 0 20%;
    font-size: 18px;
}
